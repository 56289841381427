<template>
  <div>
    <Modal v-model="viewAnnouncementModal" class-name="viewAnnouncementStyle" :title="viewAnnouncementTitle" width="1200"
      :transfer="false" :mask-closable="false">
      <div class="content_box" v-html="detailsObj.context"></div>
      <template #footer>
        <template v-if="detailsObj.isRead === 0">
          <Button class="ml15" @click="viewAnnouncementModal = false">{{ $t('key1000306') }}</Button>
          <Button class="ml15" type="primary" @click="markAsReadBtn">{{ $t('key1000138') }}</Button>
        </template>
        <template v-if="detailsObj.isRead === 1">
          <Button class="ml15" type="primary" @click="viewAnnouncementModal = false">{{ $t('key1000204') }}</Button>
        </template>
      </template>
    </Modal>
    <!--退回弹窗-->
    <returnedReasonModal ref="returnedReason" @returnedReasonBtn="returnedReasonBtn"></returnedReasonModal>
  </div>
</template>

<script>
import { alias2f085dd487c1465383484787b29fef2c } from '@/customFolder/customVueAlias.js';

import api from '@/api/';
import Mixin from "@/components/mixin/common_mixin";
import returnedReasonModal from "@/components/common/returnedReasonModal.vue";
import {verifyLoginTicket} from "@/utils/common";

export default {
  name: "viewAnnouncementModal",
  components: {returnedReasonModal},
  mixins: [Mixin],
  data() {
    let v = this;
    return {
      viewAnnouncementTitle: '',
      viewAnnouncementModal: false,
      detailsObj: {}, // 1草稿、2待修改、3待审核、4待发送、5已发送
    }
  },
  computed: {
    // 获取当前登录用户的信息
    userInfo() {
      if (this.$store.state.erpConfig) {
        return this.$store.state.erpConfig.userInfo;
      } else {
        return '';
      }
    }
  },
  created() {
    verifyLoginTicket().then((val) => {
      if (val) {
        if (['supplier', 'distributor', 'yms'].includes(this.system)) {
          this.getViewAnnouncementData();
        }
      }
    });
  },
  methods: {
    // 获取公告详情数据
    getViewAnnouncementData(noticeInfoId) {
      this.detailsObj = {};
      let urlObj = {
        yms: api.post_ymsNoticeInfo_queryNewNoticeInfo,
        supplier: api.post_ymsNoticeInfo_queryNewNoticeInfo,
        distributor: api.post_ymsNoticeInfo_queryNewNoticeInfo,
      }
      let url = urlObj[this.system];
      let query = {
        isRead: noticeInfoId ? null : 0, // 0未读  1已读
        pageNum: 1,
        merchantId: this.userInfo.merchantId,
        pageSize: 15,
        subsystemName: this.system
      };
      this.axios.post(url, query, {loading: true}).then((res) => {
        if (res.data.code === 0) {
          let data = res.data.datas;
          if (data) {
            let list = data.list || [];
            if (list.length > 0) {
              if (noticeInfoId) {
                list.map((item) => {
                  if (item.noticeInfoId === noticeInfoId) {
                    this.detailsObj = item;
                    this.viewAnnouncementTitle = item.title;
                  }
                })
              } else {
                this.detailsObj = list[0];
                this.viewAnnouncementTitle = list[0].title;
              }
              this.viewAnnouncementModal = true;
            }
          }
        }
      });
    },
    // 退回、审核通过
    checkNoticeBtn(type, returnedReason) {
      let v = this;
      let query = {
        noticeInfoId: v.detailsObj.noticeInfoId,
        operationType: type, // 操作 1审核通过、2审核退回 3取消发送
        returnedReason: returnedReason || '',
      };
      let obj = {
        1: alias2f085dd487c1465383484787b29fef2c.t('key1000307'),
        2: alias2f085dd487c1465383484787b29fef2c.t('key1000308'),
        3: alias2f085dd487c1465383484787b29fef2c.t('key1000309'),
      }
      v.axios.put(api.put_ymsNoticeInfo_checkNoticeInfo, query, {loading: true, loadingText: alias2f085dd487c1465383484787b29fef2c.t('key1000212')}).then(response => {
        v.tableLoading = false;
        if (response.data.code === 0) {
          v.$Message.success(obj[type]);
          v.viewAnnouncementModal = false;
          v.$refs['returnedReason'].returnedReasonModal = false;
          v.$emit('updateData', true);
        }
      });
    },
    // 退回
    returnedReasonBtn(val) {
      this.checkNoticeBtn(2, val);
    },
    // 标记已读
    markAsReadBtn() {
      let v = this;
      v.axios.post(api.post_ymsNoticeInfo_disable, [v.detailsObj.noticeInfoId], {loading: true, loadingText: alias2f085dd487c1465383484787b29fef2c.t('key1000212')}).then(response => {
        v.tableLoading = false;
        if (response.data.code === 0) {
          v.$Message.success(alias2f085dd487c1465383484787b29fef2c.t('key1000310'));
          v.$emit('updateData', true);
          v.viewAnnouncementModal = false;
          switch (v.system) {
            case 'supplier':
              v.getMessageNotificationData(api.get_ymsSupplierStationNotification_lastUnRead);
              break;
            case 'yms':
              v.getMessageNotificationData(api.get_ymsStationNotification_lastUnRead);
              break;
          }
        }
      });
    }
  }
}
</script>

<style lang="less" scoped>

.viewAnnouncementStyle {
  .content_box {
    max-height: 650px;
    overflow-y: auto;

    /deep/ img, p > span > img {
      max-width: 1143px !important;
    }
  }

  .ivu-modal-footer {
    .ivu-poptip /deep/ .poptipStyle {
      max-width: 400px;

      .ivu-poptip-body {
        display: flex;
        text-align: left;
        font-size: 13px;

        .ivu-poptip-body-message {
          padding: 0 !important;
        }

        .ivu-icon {
          position: initial;
        }
      }
    }
  }
}

</style>